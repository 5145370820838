<template>
	<div class="base_upload" :class="[showBorder ? 'border': '', `column_${column}`]">
		<van-uploader
			class="van_upload"
			:class="{ preview: preview }"
			upload-icon="plus"
			v-model="fileList"
			result-type="file"
			:deletable="!disabled"
			:max-count="disabled ? fileList.length : listLimit"
      :before-read="beforeRead"
			:after-read="afterReadQiniu"
			:accept="accept"
			@click-preview="clickPreview"
		/>

    <van-dialog v-model="previewShow.visible" title="预览" confirmButtonText="关闭">
      <div style="padding: 20px 0;">
        <video :src="previewShow.url" controls style="width: 100%; max-height: 300px;"></video>
      </div>
    </van-dialog>
	</div>
</template>

<script>
	import { uploadQiniu } from '@/utils/qiniu'
	export default {
		name: 'BaseUpload',
    data: () => ({
      previewShow: {
        visible: false,
        url: ''
      }
    }),
		props: {
			column: {
				type: [String, Number],
				default: 3
			},
			value: {
				type: Array,
				default: () => []
			},
			preview: {
				type: Boolean,
				default: false
			},
			limit: {
				type: [Number, String],
				default: 99999
			},
			showBorder: {
				type: Boolean,
				default: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
      accept: {
        type: String,
        default: 'image/*'
      },
      repeatUpload: {
        type: Boolean,
        default: true
      }
		},
		computed: {
			fileList: {
				get () {
					return this.value
				},
				set (val) {
					this.$emit('input', val)
				}
			},
			listLimit() {
				if (this.preview) return this.value.length
				return this.limit
			}
		},
		methods: {
      clickPreview (item) {
        // 支持的视频数据格式
        const videoAccept = 'avi,mp4,mov,muv,AVI,MP4,MOV,MUV'
        if (this.accept.startsWith('video/') || this.accept === '*') {
          const itemSuffix = item.url.slice(item.url.lastIndexOf('.') + 1)
          if (videoAccept.includes(itemSuffix)) {
            this.$set(this.previewShow, 'url', item.url)
            this.$set(this.previewShow, 'visible', true)
          }
        }
      },
      // 检测重复上传逻辑
      checkRepeatUpload (file) {
        if (!this.repeatUpload) {
          const isExist = this.fileList.some(item => {
            if (item.originData) {
              console.log(item.originData, file)
              if (item.originData.name === file.name && item.originData.size === file.size) return true
            }
            return false
          })
          if (isExist) {
            this.$toast('请勿重复上传相同的图片')
            return false
          }
        }
        return true
      },
      beforeRead (file) {
        console.log(this.repeatUpload, file, this.value)
        const imageAccept = ['.jpg', '.gif', '.png', '.webp', '.heic', '.jpeg'];
        const videoAccept = ['.avi','.mp4','.mov','.muv','.AVI','.MP4','.MOV','.MUV'];
        // 校验上传图片格式是否支持
        if (this.accept.startsWith('image/')) {
          const imgType = file.name.substr(file.name.lastIndexOf('.'))
          if (!imageAccept.includes(imgType.toLowerCase())) {
            this.$toast(`请上传${imageAccept.join('、')}格式图片`);
            return false
          }
        } else if (this.accept.startsWith('video/')) {
          // 支持的视频数据格式
          const itemSuffix = file.name.substr(file.name.lastIndexOf('.'))
          if (!videoAccept.includes(itemSuffix.toLowerCase())) {
            this.$toast(`请上传${videoAccept.join('、')}格式视频`);
            return false
          }
        } else if (this.accept === '*') {
          // 支持的视频数据格式
          const imgType = file.name.substr(file.name.lastIndexOf('.'))
          if (![...imageAccept, ...videoAccept].includes(imgType.toLowerCase())) {
            this.$toast(`上传文件格式不支持`);
            return false
          }
        }

        if (!this.checkRepeatUpload(file)) return false
        return true
      },
			afterReadQiniu(file) {
				file.status = 'uploading';
				file.message = '上传中';
        file.originData = {
          name: file.file.name,
          size: file.file.size,
          type: file.file.type,
          lastModified: file.file.lastModified,
          lastModifiedDate: this.$moment(file.file.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss')
        }
				this.$cos(
					file.file,
					null,
					() => {
						// file.status = 'uploading';
						// file.message = '上传中...';
					},
				).then((url)=>{
					file.url = url;
					file.status = 'done';
					file.message = '上传成功';
					const list = this.fileList.filter(file => file.status === 'done');
					this.$emit('success', list, file);
				}).catch((err) => {
					file.status = 'failed';
					file.message = '上传失败';
				})
			},
		}
	};
</script>
